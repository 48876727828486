import { type NavigationGuard } from "vue-router";

import { appUser, autoLogin } from "../../state/global/auth.state";
import { AdminPageName } from "../types";
import { checkRouteIsAuthorized } from "../utils/checkRouteisAuthorized.util";

export const checkRoutePermissions: NavigationGuard = async (
    to,
    from,
    next,
) => {
    const isAuthorizedRoute = checkRouteIsAuthorized(to);

    if (isAuthorizedRoute && !appUser.value) {
        await autoLogin();
    }

    if (isAuthorizedRoute && !appUser.value) {
        return next({ name: AdminPageName.Login });
    }

    return next();
};
