<template>
    <li v-if="toastMessage" :class="$style.root">
        <AffluentNote
            :class="[$style.toast, $style[toast.messageStyle || MessageStyle.Default]]"
            :messageStyle="toast.messageStyle"
            :closeAriaLabel="closeAriaLabel"
        >
            <button :class="$style.close" :aria-label="closeAriaLabel" @click.stop="close">
                <AffluentIcon :name="IconName.Cross" />
            </button>
            <!-- eslint-disable-next-line vue/no-v-html - disabled as toastMessage is sanitized-->
            <div v-if="toast.isHtml" :class="$style.message" v-html="toastMessage" />
            <div v-else :class="$style.message" v-text="toastMessage" />
        </AffluentNote>
    </li>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount } from "vue";

import { IconName } from "@affluent/frontend-ui-svg-icons";
import { MessageStyle } from "@affluent/frontend-ui-types";
import { Toast } from "@affluent/frontend-vue-use";
import { sanitizeHtml } from "@affluent/shared-util-sanitize-html";

import { DEFAULT_TOAST_TTL } from "../../../utils/constants";
import { AffluentIcon } from "../../AffluentIcon";
import { AffluentNote } from "../../AffluentNote";

export interface AffluentToastProps {
    /** Toast data */
    toast: Toast;
    /** Close toast button aria label */
    closeAriaLabel: string;
}

export type AffluentToastEvents = (event: "close") => void;

defineOptions({ name: "AffluentToast" });

const props = defineProps<AffluentToastProps>();

const emit = defineEmits<AffluentToastEvents>();
let destroyTimeout: number;

const toastMessage = computed(() =>
    props.toast.isHtml
        ? sanitizeHtml(props.toast.message, { ALLOWED_TAGS: ["a"], ALLOWED_ATTR: ["href", "target", "rel"] })
        : props.toast.message,
);

const close = () => emit("close");

const init = () => {
    const timeToLive = props.toast?.timeToLive || DEFAULT_TOAST_TTL;

    if (timeToLive !== Number.POSITIVE_INFINITY) destroyTimeout = window.setTimeout(close, timeToLive * 1000);
};

init();

onBeforeUnmount(() => {
    if (destroyTimeout !== undefined) window.clearTimeout(destroyTimeout);
});

defineExpose({ close });
</script>

<style lang="scss" module>
.root {
    position: relative;
    width: 30rem;
    flex-shrink: 0;
}

.toast {
    display: flex;
    backdrop-filter: blur(0.4rem);
    background-color: rgb(255 255 255 / 30%);
}

.default {
    background-color: rgb(var(--color-gray-100-rgb) / 80%);
}

.info {
    background-color: rgb(var(--color-info-lightest-rgb) / 80%);
}

.success {
    background-color: rgb(var(--color-success-lightest-rgb) / 80%);
}

.error {
    background-color: rgb(var(--color-error-lightest-rgb) / 80%);
}

.warning {
    background-color: rgb(var(--color-warning-lightest-rgb) / 80%);
}

.message {
    align-self: center;
    padding-right: 3.2rem;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    width: 3.2rem;
    height: 3.2rem;
}
</style>
