import type { RouteRecordRaw } from "vue-router";

import VisitorLayout from "../../views/layouts/VisitorLayout.vue";
import { AdminMenuItemId, AdminPageName, RouteParam } from "../types";
import authRoutes from "./authRoutes";

const AppLayout = () => import("../../views/layouts/AppLayout.vue");
const ErrorPage = () => import("../../views/pages/error/ErrorPage.vue");
const AgenciesSystemInfoPage = () =>
    import("../../views/pages/agencies-system-info/AgenciesSystemInfoPage.vue");
const AgencySystemInfoPage = () =>
    import(
        "../../views/pages/agencies-system-info/subpages/agency-system-info/AgencySystemInfoPage.vue"
    );
const UsersPage = () => import("../../views/pages/users/UsersPage.vue");

export const routes: RouteRecordRaw[] = [
    {
        path: "/",
        name: AdminPageName.Root,
        component: AppLayout,
        redirect: {
            name: AdminPageName.AgenciesSystemInfo,
        },
        children: [
            {
                path: "/agencies-system-info",
                name: AdminPageName.AgenciesSystemInfo,
                component: AgenciesSystemInfoPage,
                meta: {
                    sidebarItemId: AdminMenuItemId.AgenciesSystemInfo,
                    breadcrumb: {
                        id: AdminPageName.AgenciesSystemInfo,
                        label: "Agencies System Info",
                    },
                },
                children: [
                    {
                        path: `agency/:${RouteParam.Id}`,
                        name: AdminPageName.AgencySystemInfo,
                        component: AgencySystemInfoPage,
                        meta: {
                            sidebarItemId: AdminMenuItemId.AgenciesSystemInfo,
                            breadcrumb: {
                                id: AdminPageName.AgencySystemInfo,
                                label: "Info",
                            },
                        },
                    },
                ],
            },
            {
                path: "users",
                name: AdminPageName.Users,
                component: UsersPage,
                meta: {
                    sidebarItemId: AdminMenuItemId.Users,
                    breadcrumb: {
                        id: AdminPageName.Users,
                        label: "Users",
                    },
                },
            },
        ],
    },
    {
        path: "/auth",
        name: AdminPageName.AuthRoot,
        component: VisitorLayout,
        redirect: {
            name: AdminPageName.Login,
        },
        children: authRoutes,
    },

    {
        path: "/error/:code",
        name: AdminPageName.Error,
        component: ErrorPage,
    },
];
