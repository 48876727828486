import type { MIDDLEWARES } from "./middlewares";

export enum PopoverPlacement {
    Top = "top",
    TopStart = "top-start",
    TopEnd = "top-end",
    Right = "right",
    RightStart = "right-start",
    RightEnd = "right-end",
    Bottom = "bottom",
    BottomStart = "bottom-start",
    BottomEnd = "bottom-end",
    Left = "left",
    LeftStart = "left-start",
    LeftEnd = "left-end",
}

export enum PopoverStrategy {
    Fixed = "fixed",
    Absolute = "absolute",
}

export enum PopoverTheme {
    Light = "light",
    Dark = "dark",
}

export interface PopoverMiddleware {
    type: keyof typeof MIDDLEWARES;
    options?: Partial<(typeof MIDDLEWARES)[keyof typeof MIDDLEWARES]>;
}
