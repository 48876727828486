import ky, { type Options } from "ky";

import type {
    ApiResult,
    MakeApiRequestOptions,
} from "@affluent/agency-os-api-client-global";
import {
    getApiUrl,
    getRequestBody,
    getResponseBody,
} from "@affluent/frontend-util-http-api-request";

import { LocalStorageKey } from "../../types/LocalStorageKey";
import { getResponseErrorMessage } from "./apiError.utils";

const isDevelopment = import.meta.env?.DEV;

export async function makeApiRequest<
    Output,
    ResponseHeaders,
    Input extends Record<string, string> = NonNullable<unknown>,
    PathParameters extends Record<string, string> = NonNullable<unknown>,
    QueryParameters extends Record<string, string> = NonNullable<unknown>,
>(
    path: string,
    method: RequestInit["method"],
    {
        body,
        options,
        pathParameters,
        queryParameters,
        requestMimeType,
    }: MakeApiRequestOptions<Input, PathParameters, QueryParameters, Options>,
): Promise<ApiResult<Output, ResponseHeaders>> {
    const apiUrl = getApiUrl(import.meta.env?.VITE_API_URL || "", path, {
        pathParameters,
        queryParameters,
    });

    const sessionId = isDevelopment
        ? localStorage.getItem(LocalStorageKey.SessionId)
        : undefined;

    try {
        const headers: Record<string, string> | undefined = sessionId
            ? {
                  Authorization: sessionId,
              }
            : undefined;

        const requestBody: Options = getRequestBody(body, requestMimeType);

        const response = await ky(apiUrl, {
            ...options,
            ...requestBody,
            method,
            headers,
            timeout: isDevelopment ? 5 * 60_000 : options?.timeout || 10_000,
        });
        const data = await getResponseBody(response);

        /**
         * Built app (production mode) is running on the same host, so cookies
         * are used for authentication. Local app (development mode) is running
         * on different hosts (same hostname - localhost, but different ports),
         * so HTTP headers are used for authentication.
         */
        if (isDevelopment && !sessionId) {
            const newSessionId = response.headers.get("Authorization");
            if (newSessionId) {
                localStorage.setItem(LocalStorageKey.SessionId, newSessionId);
            }
        }
        return {
            data,
            responseHeaders: response.headers as ResponseHeaders,
            responseStatus: response?.status,
        };
    } catch (error) {
        const errorMessage = await getResponseErrorMessage(error, path);

        return {
            error: errorMessage,
            responseStatus: error?.response?.status,
        };
    }
}
