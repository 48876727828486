<template>
    <AffluentPopover
        :data-test-id="$attrs['data-test-id'] || 'affluent-tooltip'"
        :class="[$style.root, $style[variation]]"
        v-bind="popoverProps"
        :isVisible="isVisible"
        :anchor="anchor"
    >
        <div v-if="title" :class="$style.title" v-text="title" />
        <div :class="$style.content">
            <slot>
                <div v-text="text" />
            </slot>
        </div>
    </AffluentPopover>
</template>

<script setup lang="ts">
import { debounce } from "lodash-es";
import { computed, onBeforeUnmount, ref, watch } from "vue";

import { DEFAULT_HOVER_DEBOUNCE_TIME } from "../../utils/constants";
import {
    AffluentPopover,
    AffluentPopoverProps,
    PopoverPlacement,
    PopoverStrategy,
    PopoverTheme,
} from "../AffluentPopover";
import { TooltipAnchor, TooltipVariation } from "./types";

export interface AffluentTooltipProps {
    /** Anchor reference */
    anchor?: TooltipAnchor;
    /** Tooltip title */
    title?: string;
    /** Tooltip text */
    text?: string | number;
    /** Tooltip variation */
    variation?: TooltipVariation;
}

defineOptions({ name: "AffluentTooltip" });

const props = withDefaults(defineProps<AffluentTooltipProps>(), {
    variation: TooltipVariation.Hint,
});

const VISIBILITY_STATE_EVENTS: Record<string, boolean> = {
    mousemove: true,
    mouseenter: true,
    mouseleave: false,
    touchstart: true,
    touchend: false,
};

const isVisible = ref(false);

const htmlAnchor = computed(() => getAnchorHtmlElement(props.anchor));

const popoverProps = computed<Partial<AffluentPopoverProps>>(() => ({
    hasArrow: true,
    offset: 8,
    placement: PopoverPlacement.Bottom,
    middleware: [
        {
            type: "flip",
            options: {
                fallbackPlacements: [PopoverPlacement.Left, PopoverPlacement.Right],
            },
        },
    ],
    theme: getPopoverTheme(),
    strategy: PopoverStrategy.Fixed,
    autoUpdateOptions: { elementResize: true },
    shadowLevel: 3,
}));

const mutationObserver = new MutationObserver(() => {
    if (!htmlAnchor.value?.parentElement) isVisible.value = false;
});

const getPopoverTheme = () => (props.variation === TooltipVariation.Panel ? PopoverTheme.Light : PopoverTheme.Dark);

const setIsVisible = (event: MouseEvent | TouchEvent) => {
    const isVisibleState = VISIBILITY_STATE_EVENTS[event.type];

    if (isVisibleState && !htmlAnchor.value?.parentElement) return removeListeners(htmlAnchor.value);

    if (!Object.hasOwn(VISIBILITY_STATE_EVENTS, event.type)) return;

    isVisible.value = isVisibleState;
    debouncedSetIsVisible.cancel();
};

const debouncedSetIsVisible = debounce((event: MouseEvent) => setIsVisible(event), DEFAULT_HOVER_DEBOUNCE_TIME);

const addListeners = (element?: HTMLElement) => {
    if (!element) return;

    element.addEventListener("mousemove", debouncedSetIsVisible, { once: true });
    element.addEventListener("mouseenter", debouncedSetIsVisible);
    element.addEventListener("mouseleave", setIsVisible);
    element.addEventListener("touchstart", setIsVisible, { passive: true });
    element.addEventListener("touchend", setIsVisible, { passive: true });
    debouncedSetIsVisible(new MouseEvent("mouseenter"));
};

const removeListeners = (element?: HTMLElement) => {
    if (!element) return;

    element.removeEventListener("mouseenter", debouncedSetIsVisible);
    element.removeEventListener("mouseleave", setIsVisible);
    element.removeEventListener("touchstart", setIsVisible);
    element.removeEventListener("touchend", setIsVisible);
    setIsVisible(new MouseEvent("mouseleave"));
};

function getAnchorHtmlElement(tooltipAnchor: TooltipAnchor) {
    if (!tooltipAnchor) return;
    return tooltipAnchor instanceof HTMLElement ? tooltipAnchor : tooltipAnchor.$el;
}

onBeforeUnmount(() => removeListeners(htmlAnchor.value));

watch(
    () => props.anchor,
    (newAnchor, oldAnchor) => {
        removeListeners(getAnchorHtmlElement(oldAnchor));
        addListeners(getAnchorHtmlElement(newAnchor));
    },
);

watch(htmlAnchor, () => {
    if (!htmlAnchor.value) return mutationObserver.disconnect?.();

    mutationObserver.observe(htmlAnchor.value.parentElement, { childList: true });
});

onBeforeUnmount(() => {
    mutationObserver.disconnect?.();
});
</script>

<style lang="scss" module>
.hint {
    --root-max-width: 24rem;
    --title-color: var(--color-white);
    --title-padding: 0.4rem 0.8rem;
    --title-border-radius: var(--border-radius);
    --content-top-border: none;
    --content-color: var(--color-gray-100);
    --content-padding: 0.8rem;

    @include fontSmall;
}

.panel {
    --root-max-width: 32rem;
    --title-color: var(--color-gray-700);
    --title-padding: 0.8rem 1.6rem;
    --title-border-radius: var(--border-radius) var(--border-radius) 0 0;
    --content-top-border: var(--border-width) solid var(--color-gray-200);
    --content-color: var(--color-base-text);
    --content-padding: 1.2rem 1.6rem;
}

.root {
    z-index: var(--z-index-tooltip);
    max-width: var(--root-max-width);
    border-radius: var(--border-radius);
    color: var(--color-white);
}

.title {
    padding: var(--title-padding);
    border-radius: var(--title-border-radius);
    border-bottom: var(--content-top-border);
    color: var(--title-color);
}

.content {
    padding: var(--content-padding);
    color: var(--content-color);
    word-break: break-word;

    &:empty {
        display: none;
    }
}
</style>
