export enum AdminPageName {
    Root = "Root",
    AgenciesSystemInfo = "AgenciesSystemInfo",
    AgencySystemInfo = "AgencySystemInfo",
    AuthRoot = "AuthRoot",
    Login = "Login",
    Signup = "Signup",
    Users = "Users",
    Error = "Error",
}

export enum AdminMenuItemId {
    AgenciesGroup = "AgenciesGroup",
    AgenciesSystemInfo = "AgenciesSystemInfo",
    ManagementGroup = "ManagementGroup",
    Users = "Users",
}

export enum RouteParam {
    Id = "id",
}
export enum RouteQueryKey {
    Key = "key",
}
