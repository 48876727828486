import { DEFAULT_PAGE_SIZE } from "@affluent/frontend-vue-use";

export const DEFAULT_COUNTRY = "US";
export const DEFAULT_LOCALE = "en-US";

/** Offset from anchor in px */
export const DEFAULT_POPOVER_OFFSET = 6;
/** Default toast display time in seconds */
export const DEFAULT_TOAST_TTL = 5;
/** Default transitions and animations time in milliseconds */
export const DEFAULT_TRANSITION_TIME = 200;
export const NO_TEXT_STUB = "-";
export const DEFAULT_PAGER_BUTTONS_LENGTH = 5;
/** Default Hover debounce time in milliseconds */
export const DEFAULT_HOVER_DEBOUNCE_TIME = 350;
export const DEFAULT_SEARCH_DEBOUNCE_TIME = 300;
export const DEFAULT_PAGE_SIZES = [DEFAULT_PAGE_SIZE, 25, 50, 100];
/** Default max symbols allowed in a Tag */
export const DEFAULT_MAX_TAG_LABEL_LENGTH = 100;
/** Default max number of tags allowed in a list */
export const DEFAULT_MAX_TAGS_LENGTH = 50;
