import type { Component, ComponentPublicInstance } from "vue";

import type { AffluentTooltipProps } from "./AffluentTooltip.vue";

export enum TooltipVariation {
    Hint = "hint",
    Panel = "panel",
}
export type TooltipAnchor = ComponentPublicInstance | HTMLElement | undefined;

export interface TooltipContent {
    contentComponent?: Component | string;
    contentComponentProps?: object;
}

export type TooltipWithContent = Partial<AffluentTooltipProps> & TooltipContent;

export type AffluentTooltipType = Partial<AffluentTooltipProps> &
    Pick<AffluentTooltipProps, "anchor">;
