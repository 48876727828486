import { IconName } from "@affluent/frontend-ui-svg-icons";
import { MessageStyle } from "@affluent/frontend-ui-types";

export const getIconNameByMessageStyle = (messageStyle: MessageStyle) =>
    ({
        [MessageStyle.Default]: IconName.InfoInCircle,
        [MessageStyle.Info]: IconName.InfoInCircle,
        [MessageStyle.Success]: IconName.CheckInCircle,
        [MessageStyle.Warning]: IconName.WarningInCircle,
        [MessageStyle.Error]: IconName.WarningInCircle,
    })[messageStyle];
