import { computed, ref } from "vue";

import { type AdminUser } from "@affluent/agency-os-api-types-global";

import router from "../../router/router";
import { checkRouteIsAuthorized } from "../../router/utils/checkRouteisAuthorized.util";
import { ApiClient } from "../../services/api-client";
import { LocalStorageKey } from "../../types/LocalStorageKey";

const privateUser = ref<AdminUser>();
const isLoading = ref(false);

export const appUser = computed(() => privateUser.value);
export const isLoadingAppUser = computed(() => isLoading.value);

export const clearAppUser = () => {
    privateUser.value = undefined;
    localStorage.removeItem(LocalStorageKey.SessionId);
};

export const autoLogin = async () => {
    isLoading.value = true;

    const { data } = await ApiClient.getAdminUser();
    isLoading.value = false;
    updateAppUser(data);
};

export const updateAppUser = (user?: AdminUser) => {
    privateUser.value = user;
};

export const initAuth = () => {
    checkRouteIsAuthorized(router.currentRoute.value) && autoLogin();
};
