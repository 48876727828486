import type { RouteRecordRaw } from "vue-router";

import { AdminPageName } from "../types";

const LoginPage = () => import("../../views/pages/auth/LoginPage.vue");

const SignupPage = () => import("../../views/pages/auth/SignupPage.vue");

export default [
    {
        path: "login",
        name: AdminPageName.Login,
        component: LoginPage,
        meta: {
            browserTabTitle: "Login - Admin Affluent",
        },
    },

    {
        path: "sign-up",
        name: AdminPageName.Signup,
        component: SignupPage,
        meta: {
            browserTabTitle: "Sign Up - Admin Affluent",
        },
    },
] as RouteRecordRaw[];
