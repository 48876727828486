import type { NavigationGuard } from "vue-router";

import type { AffluentBreadcrumb } from "@affluent/ui-kit/src/components/AffluentBreadcrumbs";

import { setPageBreadcrumbs } from "../../state/global/breadcrumbs.state";

export const collectBreadcrumbs: NavigationGuard = (to, from, next) => {
    if (to.name === from.name) return next();

    const breadcrumbs = (to.matched || [])
        .filter((record) => record.meta?.breadcrumb)
        .map(
            ({ meta, name, redirect }) =>
                ({
                    id: name,
                    label: meta.breadcrumb!.label,
                    to: meta.breadcrumb!.to || redirect || { name },
                }) as AffluentBreadcrumb,
        );

    setPageBreadcrumbs(breadcrumbs);

    return next();
};
