import { type InjectionKey, provide, ref } from "vue";

import { createInjector } from "@affluent/frontend-vue-use";

import type { AffluentTooltipType, TooltipWithContent } from "./types";

export type AffluentTooltipState = ReturnType<typeof provideTooltip>;

const stateKey: InjectionKey<AffluentTooltipState> = Symbol("tooltip");

export const injectTooltip = createInjector<AffluentTooltipState>(stateKey);

export function provideTooltip() {
    const tooltip = ref<AffluentTooltipType>();
    const slotComponent = ref();
    const slotComponentProps = ref();

    const showTooltip = ({
        contentComponent,
        contentComponentProps,
        ...newTooltip
    }: TooltipWithContent) => {
        if (newTooltip.anchor && (newTooltip.text || contentComponent)) {
            tooltip.value = newTooltip;
            slotComponent.value = contentComponent;
            slotComponentProps.value = contentComponentProps;
        }
    };

    const hideTooltip = () => {
        tooltip.value = {};
        slotComponent.value = undefined;
        slotComponentProps.value = undefined;
    };

    const toggleTooltip = (
        isVisible: boolean,
        newTooltip: TooltipWithContent,
    ) => {
        if (isVisible) showTooltip(newTooltip);
        else hideTooltip();
    };

    const state = {
        tooltip,
        slotComponent,
        slotComponentProps,
        showTooltip,
        hideTooltip,
        toggleTooltip,
    };

    provide(stateKey, state);

    return state;
}
