import { createApp } from "vue";

import App from "./App.vue";
import router from "./router/router";
import { SentryService } from "./services/sentry";
import { initAuth } from "./state/global/auth.state";

async function initApp() {
    const app = createApp(App).use(router);

    SentryService.init({ app, router });

    await router.isReady();

    app.mount("#app");

    initAuth();

    window.dispatchEvent(new CustomEvent("appMounted"));
}

initApp().catch((error) => {
    SentryService.captureException(error);
    console.error(`error initialising Agency Admin app ${error.message}`);
});
