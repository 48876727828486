<template>
    <div :class="$style.root">
        <div :class="$style.background">
            <InlineSvg :class="$style.background_image" src="/img/backgrounds/stripes.svg" />
        </div>
        <main :class="$style.main">
            <slot>
                <router-view />
            </slot>
        </main>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import InlineSvg from "vue-inline-svg";

export default defineComponent({
    name: "EmptyLayout",
    components: {
        InlineSvg,
    },
});
</script>

<style lang="scss" module>
.root {
    position: relative;
    display: flex;
    overflow: auto;
    height: 100vh;
}

.background {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    inset: 0;
    pointer-events: none;
}

.background_image {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: auto;
    height: 100%;
    transform: translate(-50%, -50%);
}

.main {
    margin: auto;
}
</style>
