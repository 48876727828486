import { createRouter, createWebHistory } from "vue-router";

import { setRouteLoading } from "../state/global/route.state";
import { checkRoutePermissions } from "./guards/checkRoutePermissions.guard";
import { collectBreadcrumbs } from "./guards/collectBreadcrumbs.guard";
import { routes } from "./routes";

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(collectBreadcrumbs);
router.beforeEach(checkRoutePermissions);
router.beforeEach(() => setRouteLoading(true));
router.afterEach((to) => setRouteLoading(false, to));

export default router;
