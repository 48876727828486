import {
    autoPlacement,
    flip,
    hide,
    inline,
    shift,
    size,
} from "@floating-ui/dom";

export const MIDDLEWARES = {
    shift,
    flip,
    size,
    autoPlacement,
    hide,
    inline,
};
