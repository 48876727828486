<template>
    <TransitionGroup ref="rootRef" name="list-long" tag="ul" :class="[$style.root, isScrolledBottom && $style.scroll]">
        <AffluentToast
            v-for="[id, toast] in reversedToasts"
            :key="id"
            :data-test-id="`affluent-toast-${id}`"
            :toast="toast"
            :closeAriaLabel="closeAriaLabel"
            @close="$emit('close', Number(id))"
        />
    </TransitionGroup>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import type { Toast, Toasts } from "@affluent/frontend-vue-use";
import { ScrollDirection, useScrollable } from "@affluent/frontend-vue-use";

import AffluentToast from "./AffluentToast/AffluentToast.vue";

export interface AffluentToastsProps {
    /** Message text */
    toasts?: Toasts;
    /** Close toast button aria label */
    closeAriaLabel: string;
}

export type AffluentToastsEvents = (event: "close", index: number) => void;

defineOptions({ name: "AffluentToasts" });

const props = withDefaults(defineProps<AffluentToastsProps>(), {
    toasts: () => [],
});

defineEmits<AffluentToastsEvents>();

const rootRef = ref<HTMLElement>();

const { isScrolledBottom } = useScrollable(rootRef, [ScrollDirection.Vertical]);

const reversedToasts = computed<[string, Toast][]>(() => Object.entries(props.toasts).reverse());
</script>

<style lang="scss" module>
.root {
    position: absolute;
    z-index: var(--z-index-toasts);
    top: 5.6rem;
    right: 0;
    display: flex;
    overflow: hidden auto;
    max-height: calc(100vh - 8rem);
    flex-direction: column;
    padding-right: 2.4rem;
    gap: 1.2rem;
}

.scroll {
    @include scrollbarWindows;
}
</style>

<style lang="scss" scoped>
/* stylelint-disable selector-class-pattern */
.list-long-move,
.list-long-enter-active,
.list-long-leave-active {
    transition: all var(--transition-ease-long);
}

.list-long-leave-active {
    position: absolute;
}

.list-long-enter-from,
.list-long-leave-to {
    opacity: 0;
    transform: translateX(16rem);
}
</style>
