<template>
    <div v-if="isFirstRouteLoading" class="preloader" />
    <router-view v-else />

    <AffluentToasts :toasts="toasts" closeAriaLabel="Close" @close="removeToast($event)" />
    <AffluentTooltip v-bind="tooltip || {}" />
</template>

<script setup lang="ts">
import { isWindows } from "@affluent/frontend-util-runtime-env";
import { provideToasts } from "@affluent/frontend-vue-use";
import { provideAjv } from "@affluent/frontend-vue-use";
import { AffluentToasts } from "@affluent/ui-kit/src/components/AffluentToasts";
import { AffluentTooltip } from "@affluent/ui-kit/src/components/AffluentTooltip";
import { provideTooltip } from "@affluent/ui-kit/src/components/AffluentTooltip/tooltip.provider";

import { isFirstRouteLoading } from "./state/global/route.state";

defineOptions({ name: "App" });

const { removeToast, toasts } = provideToasts();

const { tooltip } = provideTooltip();

provideAjv();

const init = () => {
    if (isWindows) document.body.classList.add("windows");
};

init();
</script>

<style lang="scss" src="./scss/main.scss"></style>
