import type { ScopeContext } from "@sentry/types";
import * as Sentry from "@sentry/vue";

import type { AdminUser } from "@affluent/agency-os-api-types-global";

import type { SentryInitOptions } from "./types";

const SENTRY_DSN =
    "https://7fc192cb1a9f4f2e94639f23044d16ed@sentry.internal.afflu.net/23";

export const SentryService = {
    init({
        app,
        router,
        withGlobalUncaughtExceptionsTrap = true,
        withGlobalUnhandledRejectionTrap = true,
    }: SentryInitOptions) {
        Sentry.init({
            app,
            dsn: SENTRY_DSN,
            enabled: !import.meta.env.IS_DEV_ENV,
            environment: import.meta.env.VITE_TARGET_ENV,
            release: import.meta.env.VITE_APP_VERSION,
            logErrors: true,
            tracesSampleRate: 0.5,
            normalizeDepth: 11,
            integrations: [
                Sentry.browserTracingIntegration({ router }),
                Sentry.globalHandlersIntegration({
                    onerror: withGlobalUncaughtExceptionsTrap,
                    onunhandledrejection: withGlobalUnhandledRejectionTrap,
                }),
            ].filter(Boolean),
            ignoreErrors: [
                "Failed to fetch",
                "NetworkError when attempting to fetch resource.",
                "Request failed with status code",
                /^Unable to preload CSS for \/static\//,
            ],
        });
    },
    setUser(user: Partial<AdminUser>) {
        Sentry.getCurrentScope().setUser(user);
    },
    captureMessage(
        message: string,
        extra?: Record<string, unknown>,
        context?: Partial<Omit<ScopeContext, "extra">>,
    ) {
        Sentry.captureMessage(message, {
            extra,
            level: "info",
            ...context,
        });
    },
    captureException(
        error: Error,
        extra?: Record<string, unknown>,
        context?: Partial<Omit<ScopeContext, "extra">>,
    ) {
        Sentry.captureException(error, {
            extra,
            level: "error",
            ...context,
        });
    },
};
